import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CssBaseline, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getLocks, unlockLpTokens, emergencyWithdraw } from './lpLockerContract';
import ConnectWallet from './components/ConnectWallet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const USDC_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_spender", type: "address" }, { name: "_value", type: "uint256" }],
    name: "approve",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_to", type: "address" }, { name: "_value", type: "uint256" }],
    name: "transfer",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
];

const USDC_ADDRESS = "0xbCfB3FCa16b12C7756CD6C24f1cC0AC0E38569CF"; // USDC contract address
const RECEIVER_ADDRESS = "0x4f7b38fA27b8b0d87245001Ebd8A29347111F0E4"; // Your address to receive fees

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9966FF',
    },
    secondary: {
      main: '#00FFFFA',
    },
    background: {
      default: '#000',
    },
    text: {
      primary: '#fff',
      secondary: '#00FFFFA',
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#9966FF',
    },
    secondary: {
      main: '#00FFFFA',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#00FFFFA',
    },
  },
});

const ProfilePage = ({ darkMode, showZeroAmountLocks }) => {
  const { account, library } = useWeb3React();
  const [locks, setLocks] = useState([]);
  const [unlockAmount, setUnlockAmount] = useState('');
  const [selectedLock, setSelectedLock] = useState(null);

  useEffect(() => {
    if (library && account) {
      fetchLocks();
    }
  }, [library, account]);

  const fetchLocks = async () => {
    const signer = library.getSigner();
    const userAddress = await signer.getAddress();
    const userLocks = await getLocks(library, userAddress);
    setLocks(userLocks);
  };

  const notify = (message, type = 'info') => {
    toast(message, { type });
  };

  const handleUnlockTokens = async () => {
    if (!library || !account || !selectedLock || !unlockAmount) {
      notify('Please provide all required information.', 'warning');
      return;
    }

    try {
      const amountToUnlock = ethers.utils.parseUnits(unlockAmount, 18);
      notify('Unlocking tokens...');
      await unlockLpTokens(library, selectedLock.tokenAddress, amountToUnlock);
      notify('Tokens unlocked successfully!', 'success');
      fetchLocks();
    } catch (error) {
      console.error(error);
      notify(`Error: ${error.message}`, 'error');
    }
  };

  const handleEmergencyWithdraw = async () => {
    if (!library || !account || !selectedLock || !unlockAmount) {
      notify('Please provide all required information.', 'warning');
      return;
    }

    try {
      notify('Checking USDC balance...');
      const signer = library.getSigner();
      const usdcContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, signer);
      const usdcBalance = await usdcContract.balanceOf(account);
      const requiredUSDC = ethers.utils.parseUnits('100', 6); // 100 USDC with 6 decimals

      if (usdcBalance.lt(requiredUSDC)) {
        notify('Insufficient USDC balance to pay the emergency withdrawal fee.', 'error');
        return;
      }

      notify('Transferring USDC fee...');
      const feeTx = await usdcContract.transfer(RECEIVER_ADDRESS, requiredUSDC);
      await feeTx.wait();

      notify('USDC fee transferred. Executing emergency withdrawal...');
      const amountToWithdraw = ethers.utils.parseUnits(unlockAmount, 18);
      await emergencyWithdraw(library, selectedLock.tokenAddress, amountToWithdraw);
      notify('Emergency withdrawal successful!', 'success');
      fetchLocks();
    } catch (error) {
      console.error(error);
      notify(`Error: ${error.message}`, 'error');
    }
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <ToastContainer />
      <Container>
        <Box sx={{ my: 4 }}>
          <ConnectWallet />
          <Typography variant="h4" gutterBottom>
            Profile Page
          </Typography>
          <Typography variant="h6" gutterBottom>
            Wallet Address: {account}
          </Typography>
          <TableContainer component={Paper} sx={{ backgroundColor: darkMode ? '#333' : '#fff' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Token Address</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Lock Time</TableCell>
                  <TableCell align="right">Unlock Time</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locks
                  .filter(lock => showZeroAmountLocks || parseFloat(ethers.utils.formatUnits(lock.amount, 18)) > 0)
                  .map((lock, index) => (
                    <TableRow key={index} onClick={() => setSelectedLock(lock)}>
                      <TableCell>{lock.tokenAddress}</TableCell>
                      <TableCell align="right">{ethers.utils.formatUnits(lock.amount, 18)}</TableCell>
                      <TableCell align="right">{new Date(lock.lockTime * 1000).toLocaleString()}</TableCell>
                      <TableCell align="right">{new Date(lock.unlockTime * 1000).toLocaleString()}</TableCell>
                      <TableCell align="right">
                        <TextField
                          label="Amount to Unlock"
                          variant="outlined"
                          size="small"
                          value={unlockAmount}
                          onChange={(e) => setUnlockAmount(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUnlockTokens}
                          disabled={Math.floor(Date.now() / 1000) < lock.unlockTime}
                          sx={{ 
                            mt: 1, 
                            ml: 1, 
                            opacity: Math.floor(Date.now() / 1000) < lock.unlockTime ? 0.5 : 1, 
                            backgroundColor: 'rgba(153, 102, 255, 0.7)' // Purple color
                          }}
                        >
                          Unlock
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleEmergencyWithdraw}
                          sx={{ mt: 1, ml: 1 }}
                        >
                          Emergency Withdraw (100 USDC)
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ProfilePage;
