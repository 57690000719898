import React from 'react';
import { createRoot } from 'react-dom/client';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Analytics } from '@vercel/analytics/react';
import App from './App';

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000; // Optional: set polling interval if required
  return library;
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <App />
    <Analytics />
  </Web3ReactProvider>
);
