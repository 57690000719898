import { ethers } from 'ethers';

// Define the contract ABI (updated)
const abi = [
  {
    "type": "event",
    "name": "EmergencyWithdrawal",
    "inputs": [
      { "type": "address", "name": "user", "internalType": "address", "indexed": true },
      { "type": "address", "name": "tokenAddress", "internalType": "address", "indexed": false },
      { "type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "TokensLocked",
    "inputs": [
      { "type": "address", "name": "user", "internalType": "address", "indexed": true },
      { "type": "address", "name": "tokenAddress", "internalType": "address", "indexed": false },
      { "type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false },
      { "type": "uint256", "name": "unlockTime", "internalType": "uint256", "indexed": false }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "TokensUnlocked",
    "inputs": [
      { "type": "address", "name": "user", "internalType": "address", "indexed": true },
      { "type": "address", "name": "tokenAddress", "internalType": "address", "indexed": false },
      { "type": "uint256", "name": "amount", "internalType": "uint256", "indexed": false }
    ],
    "anonymous": false
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [{ "type": "uint256", "name": "", "internalType": "uint256" }],
    "name": "balances",
    "inputs": [{ "type": "address", "name": "", "internalType": "address" }]
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "emergencyWithdraw",
    "inputs": [
      { "type": "address", "name": "tokenAddress", "internalType": "address" },
      { "type": "uint256", "name": "amount", "internalType": "uint256" }
    ]
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "tuple[]",
        "name": "",
        "internalType": "struct LpLocker.Lock[]",
        "components": [
          { "type": "address", "name": "tokenAddress", "internalType": "address" },
          { "type": "uint256", "name": "amount", "internalType": "uint256" },
          { "type": "uint256", "name": "lockTime", "internalType": "uint256" },
          { "type": "uint256", "name": "unlockTime", "internalType": "uint256" }
        ]
      }
    ],
    "name": "getLocks",
    "inputs": [{ "type": "address", "name": "user", "internalType": "address" }]
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "lockLP",
    "inputs": [
      { "type": "address", "name": "tokenAddress", "internalType": "address" },
      { "type": "uint256", "name": "amount", "internalType": "uint256" },
      { "type": "uint256", "name": "lockTime", "internalType": "uint256" }
    ]
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "address",
        "name": "tokenAddress",
        "internalType": "address"
      },
      {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "lockTime",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "unlockTime",
        "internalType": "uint256"
      }
    ],
    "name": "locks",
    "inputs": [
      { "type": "address", "name": "", "internalType": "address" },
      { "type": "uint256", "name": "", "internalType": "uint256" }
    ]
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [],
    "name": "unlockLP",
    "inputs": [
      { "type": "address", "name": "tokenAddress", "internalType": "address" },
      { "type": "uint256", "name": "amount", "internalType": "uint256" }
    ]
  }
];

// Define the contract address (update with the deployed address)
const lpLockerAddress = '0x6b08fEc21676094141159Ca5336A5AF8010BFA5F';

export const getLpLockerContract = (provider) => {
  return new ethers.Contract(lpLockerAddress, abi, provider);
};

export const lockLpTokens = async (provider, tokenAddress, amount, lockTime) => {
  const signer = provider.getSigner();
  const lpLockerContract = getLpLockerContract(signer);
  const tx = await lpLockerContract.lockLP(tokenAddress, amount, lockTime, { gasLimit: 300000 });
  return tx.wait();
};

export const unlockLpTokens = async (provider, tokenAddress, amount) => {
  const signer = provider.getSigner();
  const lpLockerContract = getLpLockerContract(signer);
  const tx = await lpLockerContract.unlockLP(tokenAddress, amount, { gasLimit: 300000 });
  return tx.wait();
};

export const emergencyWithdraw = async (provider, tokenAddress, amount) => {
  const signer = provider.getSigner();
  const lpLockerContract = getLpLockerContract(signer);
  const tx = await lpLockerContract.emergencyWithdraw(tokenAddress, amount, { gasLimit: 300000 });
  return tx.wait();
};

export const getLocks = async (provider, userAddress) => {
  const lpLockerContract = getLpLockerContract(provider);
  return await lpLockerContract.getLocks(userAddress);
};
