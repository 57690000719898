import React from 'react';
import { Container, Typography, Box, Button, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9966FF',
    },
    secondary: {
      main: '#00FFFFA',
    },
    background: {
      default: '#000',
    },
    text: {
      primary: '#fff',
      secondary: '#00FFFFA',
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#9966FF',
    },
    secondary: {
      main: '#00FFFFA',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#00FFFFA',
    },
  },
});

const HomePage = ({ darkMode }) => {
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Container>
        <Box sx={{ my: 4 }}>
          <Typography variant="h3" gutterBottom>
            Welcome to WileyWest Wrap & Lock
          </Typography>
          <Typography variant="body1" paragraph>
            The LP Locker project allows users to lock their Liquidity Provider (LP) tokens for a specified period. This ensures that the tokens cannot be withdrawn until the lock time has expired, providing security and trust in decentralized finance (DeFi) projects.
          </Typography>
          <Typography variant="h4" gutterBottom>
            How to Use
          </Typography>
          <Typography variant="h6" gutterBottom>
            Step 1: Connect Your Wallet
          </Typography>
          <Typography variant="body1" paragraph>
            Click on the 'Connect Wallet' button to connect your Vitruveo wallet to the application. Ensure you have sufficient balance in USDC, VTRU, VTRO, or the NEW_TOKEN to cover the fees.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Step 2: Lock LP Tokens
          </Typography>
          <Typography variant="body1" paragraph>
            Navigate to the 'LP Locker' page. Enter the token address, amount of tokens to lock, and the lock time. Note that there is a 30 USDC fee for locking tokens. You can also pay the fee using 15 VTRU, 2000 VTRO, 240,000 SOCHAI, or 3530 HONCHO. Once the fee is transferred, approve the LP tokens and lock them.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Step 3: Unlock LP Tokens
          </Typography>
          <Typography variant="body1" paragraph>
            Once the lock time has expired, navigate to the 'Your Locks' section. Enter the amount of tokens to unlock and click on 'Unlock'. Note that unlocking can only be done after the specified lock time.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Step 4: Emergency Withdrawal
          </Typography>
          <Typography variant="body1" paragraph>
            In case of an emergency, you can withdraw your tokens before the lock time expires. This will incur a 100 USDC fee. Navigate to the 'Your Locks' section, enter the amount of tokens to withdraw, and click on 'Emergency Withdraw'.
          </Typography>
          <Typography variant="h4" gutterBottom>
            VTRU Wrap/Unwrap
          </Typography>
          <Typography variant="h6" gutterBottom>
            Step 1: Wrap VTRU Tokens
          </Typography>
          <Typography variant="body1" paragraph>
            Navigate to the 'VTRU Wrap' page. Enter the amount of VTRU tokens you want to wrap and click on 'Wrap VTRU'. Note that there is a 2 USDC fee for wrapping tokens. You can also pay the fee using 1 VTRU, 200 VTRO, 345,000 $NWIF, 25,000 SOCHAI, or 354 HONCHO. Ensure you have sufficient balance to cover the fee.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Step 2: Unwrap VTRU Tokens
          </Typography>
          <Typography variant="body1" paragraph>
            Navigate to the 'VTRU Wrap' page. Enter the amount of VTRU tokens you want to unwrap and click on 'Unwrap VTRU'. Note that there is a 1 USDC fee for unwrapping tokens. You can also pay the fee using .5 VTRU, 100 VTRO, 172,500 $NWIF, 12,500 SOCHAI, or 177 Honcho. Ensure you have sufficient balance to cover the fee.
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button variant="contained" color="primary" href="/lp-locker">
              Go to LP Locker
            </Button>
            <Button variant="contained" color="primary" href="/vtru-wrap" sx={{ ml: 2 }}>
              Go to VTRU Wrap
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default HomePage;
