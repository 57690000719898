// LockOverviewChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { ethers } from 'ethers'; // Import ethers

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LockOverviewChart = ({ locks }) => {
  const currentTime = Math.floor(Date.now() / 1000);
  const lockedTokens = locks.filter(lock => lock.unlockTime > currentTime); // Filter out unlocked tokens

  const data = {
    labels: lockedTokens.map(lock => lock.tokenAddress),
    datasets: [
      {
        label: 'Lock Amount',
        data: lockedTokens.map(lock => parseFloat(ethers.utils.formatUnits(lock.amount, 18))),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };

  return <Bar data={data} />;
};

export default LockOverviewChart;
