import React, { useState } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import {
    Container,
    Typography,
    Box,
    Button,
    TextField,
    MenuItem,
    CssBaseline,
    Paper,
    Grid,
    Snackbar,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Alert,
    createTheme,
    ThemeProvider,
} from '@mui/material';
import ConnectWallet from './components/ConnectWallet';

const CONTRACT_ADDRESS = "0xC0C0A38067Ba977676AB4aFD9834dB030901bE2d";
const USDC_ADDRESS = "0xbCfB3FCa16b12C7756CD6C24f1cC0AC0E38569CF"; // USDC contract address
const VTRO_ADDRESS = "0xDECAF2f187Cb837a42D26FA364349Abc3e80Aa5D"; // VTRO contract address
const FEE_USDC = '2000000'; // 2 USDC with 6 decimals
const FEE_USDC_UNWRAP = '1000000'; // 1 USDC with 6 decimals
const FEE_VTRU = Web3.utils.toWei('1', 'ether'); // 1 VTRU in wei
const FEE_VTRU_UNWRAP = Web3.utils.toWei('0.5', 'ether'); // 0.5 VTRU in wei
const FEE_VTRO = Web3.utils.toWei('200', 'ether'); // 200 VTRO in wei
const FEE_VTRO_UNWRAP = Web3.utils.toWei('100', 'ether'); // 100 VTRO in wei
const FEE_RECEIVER_ADDRESS = '0x8349bE4dF9f3CdB8cE74d3ec5434bA938dbf4D02';
const VITRUVE0_RPC_URL = "https://rpc.vitruveo.xyz";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#9966FF',
        },
        secondary: {
            main: '#00FFFFA',
        },
        background: {
            default: '#000',
        },
        text: {
            primary: '#fff',
            secondary: '#00FFFFA',
        },
    },
});

// Add the blinking retro effect using CSS
const retroStyle = {
    color: '#FF4500', // Retro orange color
    fontWeight: 'bold',
    fontSize: '1.2rem',
    animation: 'blink 1s step-end infinite',
    '@keyframes blink': {
        '50%': { opacity: 0 },
    },
};

const USDC_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_spender", type: "address" }, { name: "_value", type: "uint256" }],
    name: "approve",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_to", type: "address" }, { name: "_value", type: "uint256" }],
    name: "transfer",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
];

const VTRO_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_spender", type: "address" }, { name: "_value", type: "uint256" }],
    name: "approve",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_to", type: "address" }, { name: "_value", type: "uint256" }],
    name: "transfer",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
];

const CONTRACT_ABI = [
  {
    "constant": false,
    "inputs": [],
    "name": "wrap",
    "outputs": [],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "unwrap",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "type": "constructor",
    "stateMutability": "nonpayable",
    "inputs": []
  },
  {
    "type": "event",
    "name": "Approval",
    "inputs": [
      {
        "type": "address",
        "name": "owner",
        "internalType": "address",
        "indexed": true
      },
      {
        "type": "address",
        "name": "spender",
        "internalType": "address",
        "indexed": true
      },
      {
        "type": "uint256",
        "name": "value",
        "internalType": "uint256",
        "indexed": false
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "Transfer",
    "inputs": [
      {
        "type": "address",
        "name": "from",
        "internalType": "address",
        "indexed": true
      },
      {
        "type": "address",
        "name": "to",
        "internalType": "address",
        "indexed": true
      },
      {
        "type": "uint256",
        "name": "value",
        "internalType": "uint256",
        "indexed": false
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "Unwrap",
    "inputs": [
      {
        "type": "address",
        "name": "account",
        "internalType": "address",
        "indexed": true
      },
      {
        "type": "uint256",
        "name": "totalAmount",
        "internalType": "uint256",
        "indexed": false
      },
      {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256",
        "indexed": false
      },
      {
        "type": "uint256",
        "name": "rebaseAmount",
        "internalType": "uint256",
        "indexed": false
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "Wrap",
    "inputs": [
      {
        "type": "address",
        "name": "account",
        "internalType": "address",
        "indexed": true
      },
      {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256",
        "indexed": false
      }
    ],
    "anonymous": false
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      }
    ],
    "name": "allowance",
    "inputs": [
      {
        "type": "address",
        "name": "owner",
        "internalType": "address"
      },
      {
        "type": "address",
        "name": "spender",
        "internalType": "address"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [
      {
        "type": "bool",
        "name": "",
        "internalType": "bool"
      }
    ],
    "name": "approve",
    "inputs": [
      {
        "type": "address",
        "name": "spender",
        "internalType": "address"
      },
      {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      }
    ],
    "name": "balanceOf",
    "inputs": [
      {
        "type": "address",
        "name": "account",
        "internalType": "address"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      }
    ],
    "name": "balances",
    "inputs": []
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "uint8",
        "name": "",
        "internalType": "uint8"
      }
    ],
    "name": "decimals",
    "inputs": []
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [
      {
        "type": "bool",
        "name": "",
        "internalType": "bool"
      }
    ],
    "name": "decreaseAllowance",
    "inputs": [
      {
        "type": "address",
        "name": "spender",
        "internalType": "address"
      },
      {
        "type": "uint256",
        "name": "subtractedValue",
        "internalType": "uint256"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "payable",
    "outputs": [],
    "name": "deposit",
    "inputs": []
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [
      {
        "type": "bool",
        "name": "",
        "internalType": "bool"
      }
    ],
    "name": "increaseAllowance",
    "inputs": [
      {
        "type": "address",
        "name": "spender",
        "internalType": "address"
      },
      {
        "type": "uint256",
        "name": "addedValue",
        "internalType": "uint256"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "string",
        "name": "",
        "internalType": "string"
      }
    ],
    "name": "name",
    "inputs": []
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "string",
        "name": "",
        "internalType": "string"
      }
    ],
    "name": "symbol",
    "inputs": []
  },
  {
    "type": "function",
    "stateMutability": "view",
    "outputs": [
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      }
    ],
    "name": "totalSupply",
    "inputs": []
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [
      {
        "type": "bool",
        "name": "",
        "internalType": "bool"
      }
    ],
    "name": "transfer",
    "inputs": [
      {
        "type": "address",
        "name": "to",
        "internalType": "address"
      },
      {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [
      {
        "type": "bool",
        "name": "",
        "internalType": "bool"
      }
    ],
    "name": "transferFrom",
    "inputs": [
      {
        "type": "address",
        "name": "from",
        "internalType": "address"
      },
      {
        "type": "address",
        "name": "to",
        "internalType": "address"
      },
      {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      }
    ],
    "name": "unwrap",
    "inputs": [
      {
        "type": "uint256",
        "name": "amount",
        "internalType": "uint256"
      }
    ]
  },
  {
    "type": "function",
    "stateMutability": "nonpayable",
    "outputs": [
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      },
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      }
    ],
    "name": "unwrapAll",
    "inputs": []
  },
  {
    "type": "function",
    "stateMutability": "payable",
    "outputs": [
      {
        "type": "uint256",
        "name": "",
        "internalType": "uint256"
      }
    ],
    "name": "wrap",
    "inputs": []
  }
];

const VtruWrap = () => {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);
    const [wrapAmount, setWrapAmount] = useState('');
    const [unwrapAmount, setUnwrapAmount] = useState('');
    const [status, setStatus] = useState('');
    const [paymentOption, setPaymentOption] = useState('VTRU');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(true); // Open dialog by default

    const connectWallet = async () => {
        const web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions: {},
        });

        try {
            const provider = await web3Modal.connect();
            const web3Instance = new Web3(provider);
            const accounts = await web3Instance.eth.getAccounts();
            setWeb3(web3Instance);
            setAccount(accounts[0]);

            // Add custom network if not already added
            await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: "0x5D2",
                        chainName: "Vitruveo Custom Network",
                        nativeCurrency: {
                            name: "VTRU",
                            symbol: "VTRU",
                            decimals: 18,
                        },
                        rpcUrls: [VITRUVE0_RPC_URL],
                        blockExplorerUrls: ["https://explorer.vitruveo.xyz"],
                    }
                ],
            });
        } catch (error) {
            console.error("Error connecting wallet:", error);
            setStatus(`Error connecting wallet: ${error.message}`);
        }
    };

  const wrapToken = async () => {
    if (!web3 || !wrapAmount) return;

    setLoading(true);
    const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
    const amountInWei = web3.utils.toWei(wrapAmount, 'ether');
    let feeInWei, tokenContract;

    switch (paymentOption) {
      case 'USDC':
        tokenContract = new web3.eth.Contract(USDC_ABI, USDC_ADDRESS);
        feeInWei = FEE_USDC;
        break;
      case 'VTRO':
        tokenContract = new web3.eth.Contract(VTRO_ABI, VTRO_ADDRESS);
        feeInWei = FEE_VTRO;
        break;
      case 'VTRU':
      default:
        feeInWei = FEE_VTRU;
        break;
    }

    try {
      const gasPrice = await web3.eth.getGasPrice();

      if (paymentOption === 'USDC' || paymentOption === 'VTRO') {
        // Transfer token fee
        await tokenContract.methods.transfer(FEE_RECEIVER_ADDRESS, feeInWei).send({
          from: account,
          gasPrice: gasPrice,
        });
      } else if (paymentOption === 'VTRU') {
        // Transfer VTRU fee
        await web3.eth.sendTransaction({
          from: account,
          to: FEE_RECEIVER_ADDRESS,
          value: feeInWei,
          gasPrice: gasPrice,
        });
      }

      // Wrap VTRU tokens
      await contract.methods.wrap().send({
        from: account,
        value: amountInWei,
        gasPrice: gasPrice,
      });

      setStatus('VTRU tokens wrapped successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error wrapping tokens:", error);
      setStatus(`Error wrapping tokens: ${error.message}`);
    }
    setLoading(false);
  };

  const unwrapToken = async () => {
    if (!web3 || !unwrapAmount) return;

    setLoading(true);
    const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
    const amountInWei = web3.utils.toWei(unwrapAmount, 'ether');
    let feeInWei, tokenContract;

    switch (paymentOption) {
      case 'USDC':
        tokenContract = new web3.eth.Contract(USDC_ABI, USDC_ADDRESS);
        feeInWei = FEE_USDC_UNWRAP;
        break;
      case 'VTRO':
        tokenContract = new web3.eth.Contract(VTRO_ABI, VTRO_ADDRESS);
        feeInWei = FEE_VTRO_UNWRAP;
        break;
      case 'VTRU':
      default:
        feeInWei = FEE_VTRU_UNWRAP;
        break;
    }

    try {
      const gasPrice = await web3.eth.getGasPrice();

      if (paymentOption === 'USDC' || paymentOption === 'VTRO') {
        // Transfer token fee
        await tokenContract.methods.transfer(FEE_RECEIVER_ADDRESS, feeInWei).send({
          from: account,
          gasPrice: gasPrice,
        });
      } else if (paymentOption === 'VTRU') {
        // Transfer VTRU fee
        await web3.eth.sendTransaction({
          from: account,
          to: FEE_RECEIVER_ADDRESS,
          value: feeInWei,
          gasPrice: gasPrice,
        });
      }

      // Unwrap VTRU tokens
      await contract.methods.unwrap(amountInWei).send({
        from: account,
        gasPrice: gasPrice,
      });

      setStatus('VTRU tokens unwrapped successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error unwrapping tokens:", error);
      setStatus(`Error unwrapping tokens: ${error.message}`);
    }
    setLoading(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    };

    const handleDialogClose = (proceed) => {
        if (proceed) {
            setDialogOpen(false);
        } else {
            setStatus('Action canceled by user.');
        }
    };


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, backgroundColor: '#121212' }}>
                  <Dialog
                      open={dialogOpen}
                      onClose={() => handleDialogClose(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="alert-dialog-title">{"Old Contract Warning"}</DialogTitle>
                      <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              You are using an old contract address. Please proceed only if you are aware of this. Would you like to continue?
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={() => handleDialogClose(false)} color="secondary">
                              No
                          </Button>
                          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
                              Yes
                          </Button>
                      </DialogActions>
                  </Dialog>
                  <Box sx={{ mb: 4 }}>
                      <Typography variant="h3" gutterBottom>
                          VTRU Wrap and Unwrap
                      </Typography>
                      <Typography variant="body1" paragraph>
                          Wrap your VTRU tokens into a wrapped version to utilize them in various DeFi applications on the Vitruveo network. Unwrap your tokens back to VTRU when needed.
                      </Typography>
                      <Typography sx={retroStyle}>
                          ⚠️ Old Contract Address: {CONTRACT_ADDRESS}
                      </Typography>
                      {!account ? (
                          <Button variant="contained" color="primary" onClick={connectWallet}>
                              Connect Wallet
                          </Button>
            ) : (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom>
                      Wrap VTRU Tokens
                    </Typography>
                    <TextField
                      label="Amount of VTRU to wrap"
                      type="number"
                      value={wrapAmount}
                      onChange={(e) => setWrapAmount(e.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Payment Method
                    </Typography>
                    <TextField
                      select
                      value={paymentOption}
                      onChange={(e) => setPaymentOption(e.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    >
                      <MenuItem value="VTRU">VTRU (1 VTRU)</MenuItem>
                      <MenuItem value="USDC">USDC (2 USDC)</MenuItem>
                      <MenuItem value="VTRO">VTRO (200 VTRO)</MenuItem>
                    </TextField>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={wrapToken}
                      sx={{ mt: 2 }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : 'Wrap VTRU'}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom>
                      Unwrap VTRU Tokens
                    </Typography>
                    <TextField
                      label="Amount of VTRU to unwrap"
                      type="number"
                      value={unwrapAmount}
                      onChange={(e) => setUnwrapAmount(e.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      Payment Method
                    </Typography>
                    <TextField
                      select
                      value={paymentOption}
                      onChange={(e) => setPaymentOption(e.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    >
                      <MenuItem value="VTRU">VTRU (0.5 VTRU)</MenuItem>
                      <MenuItem value="USDC">USDC (1 USDC)</MenuItem>
                      <MenuItem value="VTRO">VTRO (100 VTRO)</MenuItem>
                    </TextField>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={unwrapToken}
                      sx={{ mt: 2 }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : 'Unwrap VTRU'}
                    </Button>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6">Status:</Typography>
                  <Typography variant="body1">{status}</Typography>
                </Box>
              </>
            )}
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
              {status}
            </Alert>
          </Snackbar>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default VtruWrap;
