import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Container, Typography, Box, Button, CssBaseline, AppBar, Toolbar, FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import HomePage from './HomePage';
import LpLocker from './LpLocker';
import ProfilePage from './ProfilePage';
import VtruWrap from './VtruWrap'; // Import the VtruWrap component

const App = () => {
  const [darkMode, setDarkMode] = React.useState(true);
  const [showZeroAmountLocks, setShowZeroAmountLocks] = React.useState(true);

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const handleShowZeroAmountLocksToggle = () => {
    setShowZeroAmountLocks(!showZeroAmountLocks);
  };

  return (
    <Router>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            WileyWest Wrap & Lock
          </Typography>
          <Button color="inherit" component={Link} to="/">
            Home
          </Button>
          <Button color="inherit" component={Link} to="/lp-locker">
            LP Locker
          </Button>
          <Button color="inherit" component={Link} to="/profile">
            Profile
          </Button>
          <Button color="inherit" component={Link} to="/vtru-wrap"> {/* Add the VtruWrap link */}
            VTRU Wrap
          </Button>
          <FormControlLabel
            control={<MuiSwitch checked={darkMode} onChange={handleDarkModeToggle} />}
            label="Dark Mode"
          />
          <FormControlLabel
            control={<MuiSwitch checked={showZeroAmountLocks} onChange={handleShowZeroAmountLocksToggle} />}
            label="Show Zero Amount Locks"
          />
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<HomePage darkMode={darkMode} />} />
        <Route path="/lp-locker" element={<LpLocker darkMode={darkMode} showZeroAmountLocks={showZeroAmountLocks} />} />
        <Route path="/profile" element={<ProfilePage darkMode={darkMode} showZeroAmountLocks={showZeroAmountLocks} />} />
        <Route path="/vtru-wrap" element={<VtruWrap />} /> {/* Add the route for VtruWrap */}
      </Routes>
      <footer>
        <Box sx={{ mt: 4, p: 2, border: '1px solid #555', borderRadius: '4px', backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)', color: darkMode ? '#9966FF' : '#00FFFFA', textAlign: 'center' }}>
          <Typography variant="h6">Disclaimer</Typography>
          <Typography paragraph>
            This LP Locker application is provided on an "as-is" basis, without warranties or representations of any kind. By using this application, you acknowledge and agree that the developers are not liable for any damages or losses that may arise from its use. This includes, but is not limited to, any errors, bugs, or security vulnerabilities that may be present in the application.
          </Typography>
          <Typography paragraph>
            You are solely responsible for any actions you take using this application, including but not limited to locking, unlocking, or emergency withdrawing of LP tokens. Ensure that you understand and comply with all relevant laws and regulations when using this application. The developers do not provide any guarantees regarding the legality or suitability of the application for any particular use.
          </Typography>
          <Typography paragraph>
            Always exercise caution and perform your own research before engaging in any blockchain-related activities. The developers are not responsible for any financial losses, data breaches, or other negative consequences that may occur.
          </Typography>
          <Typography paragraph>
            The fees associated with locking and emergency withdrawing of LP tokens are non-refundable. Ensure that you have sufficient funds and have entered the correct details before proceeding with any transactions.
          </Typography>
          <Typography paragraph>
            By using this application, you agree to indemnify and hold harmless the developers from any claims, liabilities, or expenses that may arise from your use of the application. Use at your own risk.
          </Typography>
          <Typography paragraph>
            By using this application, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree to these terms, do not use the application.
          </Typography>
          <Typography paragraph>
            WileyWest Creations is in no way affiliated with the Vitruveo team at all.
          </Typography>
        </Box>
      </footer>
    </Router>
  );
};

export default App;
