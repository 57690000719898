import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { Button, Container, TextField, Typography, Box, Grid, CssBaseline, FormControlLabel, FormGroup, Switch as MuiSwitch, MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getLpLockerContract, lockLpTokens, unlockLpTokens, getLocks, emergencyWithdraw } from './lpLockerContract';
import ConnectWallet from './components/ConnectWallet';
import LockOverviewChart from './LockOverviewChart';
import { parse } from 'json2csv';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const USDC_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_spender", type: "address" }, { name: "_value", type: "uint256" }],
    name: "approve",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_to", type: "address" }, { name: "_value", type: "uint256" }],
    name: "transfer",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
];

const VTRO_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_spender", type: "address" }, { name: "_value", type: "uint256" }],
    name: "approve",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_to", type: "address" }, { name: "_value", type: "uint256" }],
    name: "transfer",
    outputs: [{ name: "success", type: "bool" }],
    type: "function",
  },
];

const USDC_ADDRESS = "0xbCfB3FCa16b12C7756CD6C24f1cC0AC0E38569CF"; // USDC contract address
const VTRO_ADDRESS = "0xDECAF2f187Cb837a42D26FA364349Abc3e80Aa5D"; // VTRO contract address
const LP_LOCKER_ADDRESS = "0x6b08fEc21676094141159Ca5336A5AF8010BFA5F"; // LP Locker contract address
const RECEIVER_ADDRESS = "0x8349bE4dF9f3CdB8cE74d3ec5434bA938dbf4D02"; // Your address to receive fees

const FEE_USDC = ethers.utils.parseUnits('30', 6); // 30 USDC with 6 decimals
const FEE_VTRU = ethers.utils.parseUnits('15', 18); // 15 VTRU in wei
const FEE_VTRO = ethers.utils.parseUnits('2000', 18); // 2000 VTRO in wei

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9966FF',
    },
    secondary: {
      main: '#00FFFFA',
    },
    background: {
      default: '#000',
    },
    text: {
      primary: '#fff',
      secondary: '#00FFFFA',
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#9966FF',
    },
    secondary: {
      main: '#00FFFFA',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#00FFFFA',
    },
  },
});

const LpLocker = ({ darkMode, showZeroAmountLocks }) => {
  const { account, library } = useWeb3React();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [tokenAddress, setTokenAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [lockTime, setLockTime] = useState(new Date());
  const [unlockAmount, setUnlockAmount] = useState('');
  const [locks, setLocks] = useState([]);
  const [status, setStatus] = useState('');
  const [paymentOption, setPaymentOption] = useState('USDC'); // Payment option state

  const notify = (message, type = 'info') => {
    toast(message, { type });
  };

  const handleLockTokens = async () => {
    if (!library || !account) {
      notify('Please connect your wallet first.', 'warning');
      return;
    }

    if (!ethers.utils.isAddress(tokenAddress)) {
      notify('Invalid token address.', 'error');
      return;
    }

    const signer = library.getSigner();
    const amountInWei = ethers.utils.parseUnits(amount, 18);
    let feeInWei, tokenContract, abi;

    switch (paymentOption) {
      case 'USDC':
        tokenContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, signer);
        feeInWei = FEE_USDC;
        abi = USDC_ABI;
        break;
      case 'VTRO':
        tokenContract = new ethers.Contract(VTRO_ADDRESS, VTRO_ABI, signer);
        feeInWei = FEE_VTRO;
        abi = VTRO_ABI;
        break;
      case 'VTRU':
        feeInWei = FEE_VTRU;
        abi = null; // No ABI needed for native currency
        break;
      default:
        notify('Invalid payment option.', 'error');
        return;
    }

    try {
      if (paymentOption === 'VTRU') {
        notify(`Checking VTRU balance...`);
        const vtruBalance = await library.getBalance(account);
        if (vtruBalance.lt(feeInWei)) {
          notify('Insufficient VTRU balance to pay the lock fee.', 'error');
          return;
        }

        notify('Transferring VTRU fee...');
        const tx = await signer.sendTransaction({
          to: RECEIVER_ADDRESS,
          value: feeInWei,
        });
        await tx.wait();
      } else {
        notify(`Checking ${paymentOption} balance...`);
        const balance = await tokenContract.balanceOf(account);
        if (balance.lt(feeInWei)) {
          notify(`Insufficient ${paymentOption} balance to pay the lock fee.`, 'error');
          return;
        }

        notify(`Transferring ${paymentOption} fee...`);
        const feeTx = await tokenContract.transfer(RECEIVER_ADDRESS, feeInWei);
        await feeTx.wait();

        notify(`${paymentOption} fee transferred. Approving LP tokens...`);
        const lpTokenContract = new ethers.Contract(tokenAddress, abi, signer);
        const approvalTx = await lpTokenContract.approve(LP_LOCKER_ADDRESS, amountInWei);
        await approvalTx.wait();
      }

      const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
      const futureLockTime = Math.floor(lockTime.getTime() / 1000); // Convert lock time to seconds

      if (futureLockTime <= currentTime) {
        notify('Lock time must be in the future.', 'error');
        return;
      }

      notify('Locking tokens...');
      await lockLpTokens(library, tokenAddress, amountInWei, futureLockTime);
      notify('Tokens locked successfully!', 'success');
      fetchLocks();
    } catch (error) {
      console.error(error);
      notify(`Error: ${error.message}`, 'error');
    }
  };

  const handleUnlockTokens = async (lock) => {
    if (!library || !account) {
      notify('Please connect your wallet first.', 'warning');
      return;
    }

    try {
      const amountToUnlock = ethers.utils.parseUnits(unlockAmount, 18);
      notify('Unlocking tokens...');
      await unlockLpTokens(library, lock.tokenAddress, amountToUnlock);
      notify('Tokens unlocked successfully!', 'success');
      fetchLocks();
    } catch (error) {
      console.error(error);
      notify(`Error: ${error.message}`, 'error');
    }
  };

  const handleEmergencyWithdraw = async (lock) => {
    if (!library || !account) {
      notify('Please connect your wallet first.', 'warning');
      return;
    }

    try {
      notify('Checking USDC balance...');
      const signer = library.getSigner();
      const usdcContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, signer);
      const usdcBalance = await usdcContract.balanceOf(account);
      const requiredUSDC = ethers.utils.parseUnits('100', 6); // 100 USDC with 6 decimals

      if (usdcBalance.lt(requiredUSDC)) {
        notify('Insufficient USDC balance to pay the emergency withdrawal fee.', 'error');
        return;
      }

      notify('Transferring USDC fee...');
      const feeTx = await usdcContract.transfer(RECEIVER_ADDRESS, requiredUSDC);
      await feeTx.wait();

      notify('USDC fee transferred. Executing emergency withdrawal...');
      const amountToWithdraw = ethers.utils.parseUnits(unlockAmount, 18);
      await emergencyWithdraw(library, lock.tokenAddress, amountToWithdraw);
      notify('Emergency withdrawal successful!', 'success');
      fetchLocks();
    } catch (error) {
      console.error(error);
      notify(`Error: ${error.message}`, 'error');
    }
  };

  const fetchLocks = async () => {
    if (!library) return;

    try {
      const signer = library.getSigner();
      const userAddress = await signer.getAddress();
      const userLocks = await getLocks(library, userAddress);
      setLocks(userLocks || []); // Ensure locks is set to an array
    } catch (error) {
      console.error(error);
      setLocks([]); // Set to an empty array in case of error
    }
  };

  useEffect(() => {
    if (library) {
      fetchLocks();
    }
  }, [library, account]);

  const exportToCSV = (locks) => {
    const formattedLocks = formatLocks(locks);
    const fields = ['tokenAddress', 'amount', 'unlockTime'];
    const opts = { fields };

    try {
      const csv = parse(formattedLocks, opts);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'locks.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  };

  const formatLocks = (locks) => {
    return locks.map(lock => ({
      ...lock,
      amount: ethers.utils.formatUnits(lock.amount, 18),
      unlockTime: new Date(lock.unlockTime * 1000).toLocaleString()
    }));
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <ToastContainer />
      <Container maxWidth={isMobile ? 'xs' : 'md'}>
        <Box sx={{ my: 4 }}>
          <ConnectWallet />
          <FormGroup>
            <FormControlLabel
              control={<MuiSwitch checked={showZeroAmountLocks} onChange={() => {}} />} // Dummy handler since toggle is in App component
              label="Show Zero Amount Locks"
            />
          </FormGroup>
        </Box>
        <Typography variant="h4" gutterBottom>
          LP Locker
        </Typography>
        <Box component="form" noValidate autoComplete="off" sx={{ mb: 4 }}>
          <Typography variant="h6">Lock Tokens</Typography>
          <Typography variant="body1">Cost: 30 USDC or equivalent in VTRU or VTRO</Typography>
          <Typography variant="body2" color="error" sx={{ mt: 1, mb: 2 }}>
            Once the tokens are locked, they cannot be unlocked until the specified date.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Token Address"
                variant="outlined"
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Amount"
                variant="outlined"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Lock Time"
                  value={lockTime}
                  onChange={(newValue) => setLockTime(newValue)}
                  slots={{ textField: (params) => <TextField {...params} fullWidth variant="outlined" /> }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Payment Method
              </Typography>
              <TextField
                select
                value={paymentOption}
                onChange={(e) => setPaymentOption(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <MenuItem value="USDC">USDC (30 USDC)</MenuItem>
                <MenuItem value="VTRU">VTRU (15 VTRU)</MenuItem>
                <MenuItem value="VTRO">VTRO (2000 VTRO)</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleLockTokens} disabled={!account}>
              Lock Tokens
            </Button>
          </Box>
        </Box>

        <Typography variant="h4" gutterBottom>
          Your Locks
        </Typography>
        <Box
          sx={{
            mb: 4,
            maxHeight: '400px',
            overflowY: 'scroll',
            border: '1px solid #555',
            borderRadius: '4px',
            padding: '10px',
            backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
            color: darkMode ? '#9966FF' : '#00FFFFA',
          }}
        >
          {locks
            .filter(lock => showZeroAmountLocks || parseFloat(ethers.utils.formatUnits(lock.amount, 18)) > 0)
            .map((lock, index) => (
              <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #555', borderRadius: '4px' }}>
                <Typography>Token Address: {lock.tokenAddress}</Typography>
                <Typography>Amount: {ethers.utils.formatUnits(lock.amount, 18)}</Typography>
                <Typography>Unlock Time: {new Date(lock.unlockTime * 1000).toLocaleString()}</Typography>
                <TextField
                  fullWidth
                  label="Amount to Unlock"
                  variant="outlined"
                  value={unlockAmount}
                  onChange={(e) => setUnlockAmount(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleUnlockTokens(lock)}
                  disabled={Math.floor(Date.now() / 1000) < lock.unlockTime || !account}
                  sx={{ 
                    mt: 2, 
                    opacity: Math.floor(Date.now() / 1000) < lock.unlockTime ? 0.5 : 1, 
                    backgroundColor: 'rgba(153, 102, 255, 0.7)' // Purple color
                  }}
                >
                  Unlock
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleEmergencyWithdraw(lock)}
                  disabled={!account}
                  sx={{ mt: 2, ml: 2, backgroundColor: 'rgba(255, 0, 0, 0.7)' }}
                >
                  Emergency Withdraw (100 USDC)
                </Button>
              </Box>
            ))}
        </Box>
        {locks.length > 0 && <LockOverviewChart locks={locks} />}
        {locks.length > 0 && (
          <Button variant="contained" color="primary" onClick={() => exportToCSV(locks)}>
            Export to CSV
          </Button>
        )}
        {status && (
          <Typography variant="body1" color="error">
            {status}
          </Typography>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default LpLocker;
